// .page-form
//   > .page-form__header
//   > .page-form__content
//   > .page-form__floating-actions
.page-form {
  $self: '.page-form';
  padding-bottom: 20px;

  &__floating-actions {
    position: fixed;
    bottom: 0;
    width: 100%;
    background: #fff;
    padding: 14px 18px;
    border-top: 1px solid rgb(210, 221, 226);
    box-shadow: 0px -1px 4px 0px rgba(0, 0, 0, 0.05);

    .bp4-button-group{
      
      .bp4-button{
        &:not(:last-child),
        &.bp4-popover-wrapper:not(:last-child) {
          border-right: 1px solid rgba(92, 112, 127, 0.3);
          margin-right: 0;

          &.bp4-intent-primary{
            border-right: 1px solid rgba(255, 255, 255, 0.3);
          }
        }
      }
    }
  }
  &--strip {
    #{$self}__header-fields {
      width: 85%;

      .bp4-form-group{
        margin-bottom: 16px;
      }

      > .bp4-form-group:last-of-type{
        margin-bottom: 0;
      }
    }
    #{$self}__header {
      background-color: #FFF;
      padding: 25px 32px;
      border-bottom: 1px solid #d2dce2;

      .bp4-form-group.bp4-inline label.bp4-label {
        font-weight: 500;
      }
    }

    #{$self}__body {
      padding: 18px 32px 0;
    }

    #{$self}__footer {
      margin: 20px 0 0 0;
      padding-left: 32px;
      padding-right: 32px;

      label.bp4-label{
        font-weight: 500;
      }
    }
  }
}
