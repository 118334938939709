
// Setup initializing form
.setup-initializing-form {
  width: 95%;
  margin: 0 auto;
  padding: 16% 0 0;

  .bp4-progress-bar {
    background: rgba(92, 112, 128, 0.2);
    border-radius: 40px;
    display: block;
    height: 6px;
    overflow: hidden;
    position: relative;
    width: 80%;
    margin: 0 auto;

    .bp4-progress-meter {
      background-color: #809cb3;
    }
  }

  &__title {
    text-align: center;
    margin-top: 35px;

    h1 {
      font-size: 22px;
      font-weight: 600;
      color: #454c59;
      margin-top: 0;
      margin-bottom: 14px;
    }

    .paragraph {
      width: 70%;
      margin: 0 auto;
      color: #2e4266;
    }
  }
}